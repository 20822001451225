import GATSBY_COMPILED_MDX from "/opt/build/repo/content/subcategories/bloczki-akustyczne.mdx";
import React, {useMemo} from 'react';
import {graphql} from 'gatsby';
import Seo from '../components/seo';
import AnimationWrapper from '../components/animationWrapper';
import ContactSection from "../components/contactSection";
import Container from '../components/container';
import TextSection from '../components/textSection';
import IconSection from '../components/iconSection';
import ProductTilesSection from '../components/productTilesSection';
import Header from '../components/header';
import Layout from '../components/layout';
import "./subcategory-page.css";
const CategoryPage = ({data, location, children}) => {
  const categorySlug = data.page.frontmatter.main_category;
  const categoryName = categorySlug.replace('-', ' ').replace('sci', 'ści').replace('pelnia', 'pełnia').replace('jace', 'jące');
  const categoryNameCapitalised = categoryName.charAt(0).toUpperCase() + categoryName.slice(1);
  const title = data.page.frontmatter.title || `Czamaninek ➤ Ekologiczne materiały budowlane ✔️ Polski producent ⭐ Sprawdź!"`;
  const description = data.page.frontmatter.meta_description || `Czamaninek ➤ ${data.page.frontmatter.title} ✔️ Ekologiczne materiały budowlane ✔️ Polski producent ⭐ Sprawdź!"`;
  const components = {};
  console.log('data', data.page.frontmatter.text_section_main);
  return React.createElement(Layout, {
    location: location,
    path: categorySlug
  }, React.createElement(Seo, {
    title: title,
    description: description
  }), React.createElement(AnimationWrapper, null, React.createElement(Header, {
    data: data.page.frontmatter.header,
    slug: data.page.frontmatter.slug
  })), data.page.frontmatter.text_section_main.active && React.createElement(Container, null, React.createElement(AnimationWrapper, null, React.createElement(TextSection, {
    className: "description-medium",
    data: data.page.frontmatter.text_section_main
  }))), React.createElement("section", {
    id: "zalety-aku-section",
    className: "page-section"
  }, React.createElement(Container, null, React.createElement(AnimationWrapper, {
    animationType: "fadeIn"
  }, React.createElement(IconSection, {
    data: data.page.frontmatter,
    location: data.page.frontmatter.slug
  })))), React.createElement("section", {
    id: `${categorySlug}-tiles-section`,
    className: "product-tiles-section"
  }, React.createElement(ProductTilesSection, {
    data: data.productTiles,
    subcategoryName: data.page.frontmatter.slug,
    location: data.page.frontmatter.slug
  })), data.page.frontmatter.text_section_bottom.active && React.createElement(Container, null, React.createElement(AnimationWrapper, null, React.createElement(TextSection, {
    className: "description-medium",
    data: data.page.frontmatter.text_section_bottom
  }))), React.createElement("section", {
    id: "contact-data",
    className: "page-section"
  }, React.createElement(ContactSection, {
    data: data.contactSection.frontmatter.contact_section
  })));
};
export const query = graphql`
  query(
    $slug: String,
  ) {
    page: mdx(frontmatter: { slug: { eq: $slug }, type: { eq: "subcategory" } }) {
      frontmatter{
        title
        meta_description
        slug
        type
        main_category
        header{
          title
          breadcrumbs {
            title
            url
          }
          overlay
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        text_section_main{
          active
          title
          title_visible
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          align
          text
          button_text
          button_url
          button_style
        }
        icons{
          title
          text
          image{
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        text_section_bottom{
          active
          title
          title_visible
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          align
          text
          button_text
          button_url
          button_style
        }
      }
    }
    productTiles: allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {
        internal: {contentFilePath: {regex: "/(products)/"}},
        frontmatter: {
          # category: {eq: $category},
          subcategory: {eq: $slug},
        }
      }
    ){
      nodes {
        frontmatter {
          name
          slug
          subtitle
          lambda
          desc_short
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit:COVER}
                webpOptions: {quality:95}
              )
            }
          }
          image_second {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit:COVER}
                webpOptions: {quality:95}
              )
            }
          }
        }
        id
        body
      }
    }
    contactSection: mdx(
      frontmatter: {slug: {eq: "contact-section"}, type: {eq: "global"}}
    ){
      frontmatter{
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          image_object_fit
          image_object_position
        }
      }
    }
  }
`;
CategoryPage
export default function GatsbyMDXWrapper(props) {
  return React.createElement(CategoryPage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
